import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import SimonImgW from '../../images/simon.webp'
import SimonImg from '../../images/simon.png'

import '../../styles/simon.scss'

export default function Simon({ children, location }) {
	return (
		<Layout currentPath={location}>
			<SEO title="Simón Sánchez | Sobre Nosotros" />
			<div className="simon">
				<Link to="/nosotros/quienes-somos" className="back">
					&larr;Volver
				</Link>
				<div className="simon__title">
					<div className="simon__texture" />
					<h1>¿QUIÉNES SOMOS?</h1>
				</div>
				<div className="simon__content">
					<div className="simon__content__leftbar" />
					<div className="simon__inner-content">
						<h2 className="title--top">¿QUIÉN ES</h2>
						<h2 className="title--bottom">SIMÓN SÁNCHEZ?</h2>
					</div>
					<div className="simon__content__rightbar" />
				</div>
				<div className="simon__subcontent">
					<div className="simon__photo">
						<picture>
							<source srcSet={SimonImgW} type="image/webp" />
							<source srcSet={SimonImg} type="image/png" />
							<img src={SimonImg} alt="Simón Sánchez Grupo Ínsula" />
						</picture>
					</div>
					<div className="simon__subcontent__right">
						<h4>Managing Director / Founder</h4>
						<div className="inner">
							<div className="leftbar" />
							<div>
								<p>
									“A quien bien obra, bien le va”. Los buenos actos hablan por sí solos y son capaces
									de transformar y generar un impacto positivo tanto en las personas como en los
									espacios, por eso cada proyecto tiene el compromiso, la entrega y el corazón para
									que eso siempre suceda.
								</p>
								<p>
									No es suficiente con tener una idea, sino también mejorarla, ejecutarla y verla
									materializada hasta el último detalle. Siempre con calidez humana, responsabilidad
									con las personas y el medio ambiente, disciplina, innovación, resiliencia, visión
									sistémica, pensamiento crítico y acción.
								</p>
							</div>

							<div className="rightbar" />
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}
